import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import MainLayOut from "./components/MainLayOut";
import Home from "./pages/Home";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Gallery from "./pages/gallery/Gallery";
import Services from "./pages/Services/GardenDesign";
import GardenDesign from "./pages/Services/GardenDesign";
import Plantation from "./pages/Services/Plantation";
import PottedPlantArrangements from "./pages/Services/PottedPlantArrangements";
import TreesArrangement from "./pages/Services/TreesArrangement";
import MahoganyPlants from "./pages/gallery/MahoganyPlants";
import MangoPlant from "./pages/gallery/MangoPlant";
import LemanPlant from "./pages/gallery/LemanPlant";
import GouvaPlant from "./pages/gallery/GouvaPlant";
import AppleBerPlant from "./pages/gallery/AppleBerPlant ";
import ChikuPlant from "./pages/gallery/ChikuPlant";
import AmlaPlant from "./pages/gallery/AmlaPlant";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<MainLayOut />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gardendesign" element={<GardenDesign />} />
          <Route path="/plantation" element={<Plantation />} />
          <Route path="/treesarrangement" element={<TreesArrangement />} />
          <Route
            path="/pottedplantarrangements"
            element={<PottedPlantArrangements />}
          />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/mahoganyplants" element={<MahoganyPlants />} />
          <Route path="/mangoplant" element={<MangoPlant />} />
          <Route path="/lemanplant" element={<LemanPlant />} />
          <Route path="/gouvaplant" element={<GouvaPlant />} />
          <Route path="/appleberplant" element={<AppleBerPlant />} />
          <Route path="/chikuplant" element={<ChikuPlant />} />
          <Route path="/amlaplant" element={<AmlaPlant />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
