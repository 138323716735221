import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div>
        <section
          className="page-header clearfix"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/page-header-bg.jpg)",
          }}
        >
          <div className="container">
            <div className="page-header__inner text-center clearfix">
              <ul className="thm-breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>About</li>
              </ul>
              <h2>About Us</h2>
            </div>
          </div>
        </section>
        <section className="about-three">
          <div className="about-three__shape" />
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-7">
                <div className="about-three__content-box">
                  <div className="sec-title">
                    <div className="icon">
                      <img
                        src="assets/images/resources/sec-title-icon1.png"
                        alt
                      />
                    </div>
                    <h2 className="sec-title__title">About</h2>
                  </div>
                  <div className="about-three__content-box-inner">
                    <h2>We’re Leader in Agriculture Market</h2>
                    <p>
                      The Plantage Organic Agriculture Company is engaged in
                      production and marketing of Tissue Culture Teak plants
                      that offer high farm income to the farmers.Plantage
                      Organic Agriculture Teak plants are products from high
                      yielding, Tissue culture plants after years of research
                      and our products are the farmers' first choice to make
                      fortunes in agriculture.The other products recently
                      introduced, are Micronutrients, Plant Growth Promoters and
                      Bio Fertilizers, which are helping the farmers to attain
                      20 to 25% more yields.
                    </p>
                    <p className="mt-lg-3">
                      The company is achieving excellent results year after
                      year, by developing a wide customer base throughout the
                      country. It has become a market leader and has created a
                      niche for it self in the market with its high quality
                      products and customer service. The company provides
                      excellent guidance and after sales services to the
                      customers by its qualified and dedicated Agriculture
                      Officers for better yields implementing the latest
                      scientific methods of cultivation.
                    </p>
                  </div>
                  <div className="about-three__content-box-btn">
                    <Link to="/gallery" className="thm-btn">
                      Discover more
                    </Link>
                  </div>
                  <div className="about-three__arrow float-bob-y" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-5">
                <div className="about-three__img-box">
                  <img
                    src="assets/images/resources/best.png"
                    className="about-three__img-icon"
                    alt
                  />
                  <div className="about-three__img-box-img">
                    <div className="about-three__img-box-img-inner">
                      <img src="assets/images/about/about-v3-img1.jpg" alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section
        className="video-one jarallax clearfix"
        data-jarallax
        data-speed="0.2"
        data-imgposition="50% 0%"
        style={{
          backgroundImage: "url(assets/images/backgrounds/video-one-bg.jpg)",
        }}
      >
        <div className="video-one-border" />
        <div className="video-one-border video-one-border-two" />
        <div className="video-one-border video-one-border-three" />
        <div className="video-one-border video-one-border-four" />
        <div className="video-one-border video-one-border-five" />
        <div className="video-one-border video-one-border-six" />
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="video-one__wrpper">
                <div className="video-one__left">
                  <div className="video-one__leaf" />
                  <h2 className="video-one__title">
                    Agriculture Matters to <br />
                    the Future of Development
                  </h2>
                  <div className="video-one__btn">
                    <Link to="#" className="thm-btn">
                      Discover more
                    </Link>
                  </div>
                </div>
                <div className="video-one__right">
                  <div
                    className="icon wow zoomIn"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                  >
                    <Link
                      className="video-popup"
                      title=" Video"
                      to="/"
                      //   target="_blanck"
                    >
                      <span className="icon-play-button-1" />
                    </Link>
                    <span className="border-animation border-1" />
                    <span className="border-animation border-2" />
                    <span className="border-animation border-3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Meet Client */}

      {/* <section className="meet-farmers-one meet-farmers-one--about">
        <div className="container">
          <div className="sec-title text-center">
            <div className="icon">
              <img src="assets/images/resources/sec-title-icon1.png" alt />
            </div>
            <h2 className="sec-title__title">Meet Client</h2>
          </div>
          <div className="row">
            <div
              className="col-xl-3 col-lg-6  wow fadeInLeft"
              data-wow-delay="0ms"
              data-wow-duration="1000ms"
            >
              <div className="meet-farmers-one__single">
                <div className="meet-farmers-one__single-img">
                  <img src="assets/images/resources/C1.jpeg" alt />
                </div>
                <div className="meet-farmers-one__single-title text-center">
                  <p>Farmer</p>
                  <h2>
                    <Link to="">OmPrakesh</Link>
                  </h2>
                  <div className="social-link">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6  wow fadeInLeft"
              data-wow-delay="100ms"
              data-wow-duration="1000ms"
            >
              <div className="meet-farmers-one__single">
                <div className="meet-farmers-one__single-img">
                  <img src="assets/images/resources/C1.jpeg" alt />
                </div>
                <div className="meet-farmers-one__single-title text-center">
                  <p>Farmer</p>
                  <h2>
                    <Link to="">Prakesh</Link>
                  </h2>
                  <div className="social-link">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6  wow fadeInRight"
              data-wow-delay="0ms"
              data-wow-duration="1000ms"
            >
              <div className="meet-farmers-one__single">
                <div className="meet-farmers-one__single-img">
                  <img src="assets/images/resources/C1.jpeg" alt />
                </div>
                <div className="meet-farmers-one__single-title text-center">
                  <p>Farmer</p>
                  <h2>
                    <Link to="">Omkar</Link>
                  </h2>
                  <div className="social-link">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6  wow fadeInRight"
              data-wow-delay="100ms"
              data-wow-duration="1000ms"
            >
              <div className="meet-farmers-one__single">
                <div className="meet-farmers-one__single-img">
                  <img src="assets/images/resources/C1.jpeg" alt />
                </div>
                <div className="meet-farmers-one__single-title text-center">
                  <p>Farmer</p>
                  <h2>
                    <Link to="">Prakesh </Link>
                  </h2>
                  <div className="social-link">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default About;
