import React from "react";
import { Link } from "react-router-dom";


const RecentWorks = () => {
  return (
    <>
      <section className="meet-farmers-one">
        <div className="container">
          <div className="sec-title text-center">
            <div className="icon">
              <img src="assets/images/resources/sec-title-icon1.png" alt />
            </div>
            <h6 className="sec-title__title">Recent Works</h6>
          </div>
          <div className="row">
            <div
              className="col-xl-3 col-lg-6  wow fadeInLeft"
              data-wow-delay="0ms"
              data-wow-duration="1000ms"
            >
              <div className="meet-farmers-one__single">
                <div className="meet-farmers-one__single-img">
                  <img src="assets/images/resources/1.JPG" alt />
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6  wow fadeInLeft"
              data-wow-delay="100ms"
              data-wow-duration="1000ms"
            >
              <div className="meet-farmers-one__single">
                <div className="meet-farmers-one__single-img">
                  <img src="assets/images/resources/2.JPG" alt />
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6  wow fadeInRight"
              data-wow-delay="0ms"
              data-wow-duration="1000ms"
            >
              <div className="meet-farmers-one__single">
                <div className="meet-farmers-one__single-img">
                  <img src="assets/images/resources/4.JPEG" alt />
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6  wow fadeInRight"
              data-wow-delay="100ms"
              data-wow-duration="1000ms"
            >
              <div className="meet-farmers-one__single">
                <div className="meet-farmers-one__single-img">
                  <img src="assets/images/resources/3.JPEG" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="video-one video-one--two jarallax clearfix"
        data-jarallax
        data-speed="0.2"
        data-imgposition="50% 0%"
        style={{
          backgroundImage: "url(assets/images/backgrounds/video-one-bg.jpg)",
        }}
      >
        <div className="video-one-border" />
        <div className="video-one-border video-one-border-two" />
        <div className="video-one-border video-one-border-three" />
        <div className="video-one-border video-one-border-four" />
        <div className="video-one-border video-one-border-five" />
        <div className="video-one-border video-one-border-six" />
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="video-one__wrpper">
                <div className="video-one__left">
                  <div className="video-one__leaf" />
                  <h2 className="video-one__title">
                    Agriculture Matters to <br />
                    the Future of Development
                  </h2>
                  <div className="video-one__btn">
                    <Link to="/gallery" className="thm-btn">
                      Discover more
                    </Link>
                  </div>
                </div>
                <div className="video-one__right">
                  <div
                    className="icon wow zoomIn"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                  >
                    <Link
                      className="video-popup"
                      title=" Video"
                      to="/"
                      // target="_blank"
                    >
                      <span className="icon-play-button-1" />
                    </Link>
                    <span className="border-animation border-1" />
                    <span className="border-animation border-2" />
                    <span className="border-animation border-3" />
                  </div>
                  <div className="title">
                    <h2>Watch the video</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecentWorks;
