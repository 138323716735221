import React from "react";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  return (
    <>
       <div style={{ overflow: "" }}>
        <section className="main-slider main-slider-one main-slider-one--two">
          <div
            className="swiper-container thm-swiper__slider"
            data-swiper-options='{"slidesPerView": 1, "loop": true, "effect": "fade", "pagination": {
      "el": "#main-slider-pagination",
      "type": "bullets",
      "clickable": true
      },
      "navigation": {
      "nextEl": "#main-slider__swiper-button-next",
      "prevEl": "#main-slider__swiper-button-prev"
      },
      "autoplay": {
      "delay": 7000
      }}'
          >
            <div className="swiper-wrapper">
              {/* <SliderHomeBanner /> */}

              <div className="swiper-slide">
                <div
                  className="image-layer"
                  style={{
                    backgroundImage:
                      "url(assets/images/backgrounds/main-slider-v2-1.jpg)",
                  }}
                />
                <div className="image-layer-overlay" />
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="main-slider-inner text-center">
                        <div className="main-slider__content">
                          <span className="main-slider-tagline">
                            We’re producing natural goods
                          </span>
                          <h2 className="main-slider__title">
                            Plantation <span>&amp;</span>
                            <br /> Organic Products
                          </h2>
                        </div>
                        <div className="main-slider__button-box">
                          <Link to="/gallery" className="thm-btn">
                            Discover more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div
                  className="image-layer"
                  style={{
                    backgroundImage:
                      "url(assets/images/backgrounds/main-slider-v2-2.jpg)",
                  }}
                />
                <div className="image-layer-overlay" />
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="main-slider-inner text-center">
                        <div className="main-slider__content">
                          <span className="main-slider-tagline">
                            We’re producing natural goods
                          </span>
                          <h2 className="main-slider__title">
                            Organic Products <span>&amp;</span>
                            <br />
                            Agriculture
                          </h2>
                        </div>
                        <div className="main-slider__button-box">
                          <Link to="/gallery" className="thm-btn">
                            Discover more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div
                  className="image-layer"
                  style={{
                    backgroundImage:
                      "url(assets/images/backgrounds/main-slider-v2-3.jpg)",
                  }}
                />
                <div className="image-layer-overlay" />
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="main-slider-inner text-center">
                        <div className="main-slider__content">
                          <span className="main-slider-tagline">
                            We’re producing natural goods
                          </span>
                          <h2 className="main-slider__title">
                            Agriculture <span>&amp;</span>
                            <br /> Organic Products
                          </h2>
                        </div>
                        <div className="main-slider__button-box">
                          <Link to="/gallery" className="thm-btn">
                            Discover more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-pagination" id="main-slider-pagination" />
            <div className="main-slider__nav">
              <div
                className="swiper-button-prev"
                id="main-slider__swiper-button-next"
              >
                <span className="icon-right-arrow-2" />
              </div>
              <div
                className="swiper-button-next"
                id="main-slider__swiper-button-prev"
              >
                <span className="icon-right-arrow-2" />
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  );
};

export default HomeBanner;
