import React, { useState } from "react";
import { Link } from "react-router-dom";

const Hadear = () => {
  const [toggle1, setToggle1] = useState(false);
  const [toggleservices, setToggleservices] = useState(false);
  const [toggleGallery, setToggleGallery] = useState(false);

  const CloseClick = () => {
    setToggleservices(false);
    setToggle1(false);
  };
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div>
          <header className="main-header main-header--one main-header--one--two  clearfix">
            <div className="main-header--one__wrapper">
              <div className="main-header--one__bottom clearfix">
                <div className="auto-container">
                  <div className="main-header--one__bottom-inner">
                    <div className="main-header--one__bottom-middel">
                      <div className="logo">
                        <Link to="/">
                          <img
                            src="assets/images/resources/logo1.png"
                            alt
                            className="light-logo"
                          />
                        </Link>
                      </div>
                    </div>
                    <nav className="main-menu main-menu--1">
                      <div className="main-menu__inner">
                        {toggle1 === false ? (
                          <>
                            <Link to="#" className="mobile-nav__toggler">
                              <i
                                className="fa fa-bars"
                                onClick={() => setToggle1(!toggle1)}
                              />
                            </Link>
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="stricky-one-logo">
                          <div className="logo">
                            <Link to="/">
                              <img
                                src="assets/images/resources/logo-1.png"
                                className="dark-logo"
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>

                        <div className="main-header--one__bottom-left">
                          <ul className="main-menu__list">
                            <li className="dropdown megamenu">
                              <Link to="/">Home</Link>
                            </li>
                            <li>
                              <Link to="/about">About</Link>
                            </li>
                            <li className="dropdown">
                              <Link to="/gardendesign">Services</Link>
                              <ul>
                                <li>
                                  <Link to="/gardendesign">Garden Design</Link>
                                </li>
                                <li>
                                  <Link to="plantation">Plantation</Link>
                                </li>
                                <li>
                                  <Link to="pottedplantarrangements">
                                    Potted Plant Arrangements
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/treesarrangement">
                                    Trees Arrangement
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li className="dropdown">
                              <Link to="/gallery">Gallery</Link>
                              <ul>
                                <li>
                                  <Link to="/mahoganyplants">
                                    Mahogany Plants
                                  </Link>
                                </li>
                                <li>
                                  <Link to="mangoplant">Mango Plant</Link>
                                </li>
                                <li>
                                  <Link to="lemanplant">Leman Plant</Link>
                                </li>

                                <li>
                                  <Link to="/gouvaplant">Gouva Plant</Link>
                                </li>
                                <li>
                                  <Link to="/appleberplant">
                                    Apple Ber Plant
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/chikuplant">Chiku Plant</Link>
                                </li>
                                <li>
                                  <Link to="/amlaplant">Amla Plant</Link>
                                </li>
                              </ul>
                            </li>
                            <li className="dropdown">
                              <Link to="/contact">Career</Link>
                            </li>
                            <li>
                              <Link to="/contact">Contact</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>

                    {toggle1 === true ? (
                      <>
                        <div className="mobile-nav__wrapper expanded">
                          <div className="mobile-nav__overlay mobile-nav__toggler" />
                          <div className="mobile-nav__content">
                            <span className="mobile-nav__close mobile-nav__toggler">
                              <i
                                className="fa fa-times"
                                onClick={() => setToggle1(!toggle1)}
                              />
                            </span>
                            <div className="logo-box">
                              <Link to="/" aria-label="logo image">
                                <img
                                  src="assets/images/resources/logo1.png"
                                  width={155}
                                  alt=""
                                  onClick={() => setToggle1(!toggle1)}
                                />
                              </Link>
                            </div>
                            <div className="mobile-nav__container">
                              <ul className="main-menu__list">
                                <li
                                  className="dropdown megamenu"
                                  onClick={() => setToggle1(!toggle1)}
                                >
                                  <Link to="/">Home</Link>
                                </li>
                                <li onClick={() => setToggle1(!toggle1)}>
                                  <Link to="/about">About</Link>
                                </li>

                                <li
                                  className="dropdown"
                                  onClick={() =>
                                    setToggleservices(!toggleservices)
                                  }
                                >
                                  <Link
                                    to="/"
                                    className="expanded"
                                    onClick={() =>
                                      setToggleservices(!toggleservices)
                                    }
                                  >
                                    Services
                                    <button
                                      aria-label="dropdown toggler"
                                      onClick={() =>
                                        setToggleservices(!toggleservices)
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-down"
                                        onClick={() =>
                                          setToggleservices(!toggleservices)
                                        }
                                      />
                                    </button>
                                  </Link>
                                  {toggleservices === true ? (
                                    <>
                                      <ul style={{ display: "block" }}>
                                        <li>
                                          <Link
                                            to="/gardendesign"
                                            onClick={() => CloseClick()}
                                          >
                                            Garden Design
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/plantation"
                                            onClick={() => CloseClick()}
                                          >
                                            Plantation
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/pottedplantarrangements"
                                            onClick={() => CloseClick()}
                                          >
                                            Potted Plant Arrangements
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/treesarrangement"
                                            onClick={() => CloseClick()}
                                          >
                                            Trees Arrangement
                                          </Link>
                                        </li>
                                      </ul>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </li>

                                <li
                                  className="dropdown"
                                  onClick={() =>
                                    setToggleGallery(!toggleGallery)
                                  }
                                >
                                  <Link to="/gallery" className="expanded">
                                    Gallery
                                    <button
                                      aria-label="dropdown toggler"
                                      onClick={() =>
                                        setToggleGallery(!toggleGallery)
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-down"
                                        onClick={() =>
                                          setToggleGallery(!toggleGallery)
                                        }
                                      />
                                    </button>
                                  </Link>

                                  {toggleGallery === true ? (
                                    <>
                                      <ul style={{ display: "block" }}>
                                        <li>
                                          <Link
                                            to="/mahoganyplants"
                                            onClick={() => CloseClick()}
                                          >
                                            Mahogany Plants
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/mangoplant"
                                            onClick={() => CloseClick()}
                                          >
                                            Mango Plant
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/lemanplant"
                                            onClick={() => CloseClick()}
                                          >
                                            Leman Plant
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/gouvaplant"
                                            onClick={() => CloseClick()}
                                          >
                                            Gouva Plant
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/appleberplant"
                                            onClick={() => CloseClick()}
                                          >
                                            Apple Ber Plant
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/chikuplant"
                                            onClick={() => CloseClick()}
                                          >
                                            Chiku Plant
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/amlaplant"
                                            onClick={() => CloseClick()}
                                          >
                                            Amla Plant
                                          </Link>
                                        </li>
                                      </ul>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </li>

                                <li onClick={() => setToggle1(!toggle1)}>
                                  <Link to="/contact">Career</Link>
                                </li>
                                <li onClick={() => setToggle1(!toggle1)}>
                                  <Link to="/contact">Contact</Link>
                                </li>
                              </ul>
                            </div>
                            <ul className="mobile-nav__contact list-unstyled">
                              <li>
                                <i className="icon-phone-call" />
                                <Link to="mailto:needhelp@packageName__.com">
                                  poagriculture@gmail.com
                                </Link>
                              </li>
                              <li>
                                <i className="icon-letter" />
                                {/* <Link to="+918817242006">+91 8817242006</Link> */}
                                <a href="tel:+918817242006"> +91 8817242006</a>

                              </li>
                            </ul>
                            <div className="mobile-nav__top">
                              <div className="mobile-nav__social">
                                <Link to="#" className="fab fa-twitter" />
                                <Link
                                  to="#"
                                  className="fab fa-facebook-square"
                                />
                                <Link to="#" className="fab fa-pinterest-p" />
                                <Link to="#" className="fab fa-instagram" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="main-header--one__bottom-right clearfix">
                      <div className="search-cart">
                        <Link to="#" className="search search-toggler">
                          <span className="icon-magnifying-glass" />
                        </Link>
                        <Link to="#" className="cart mini-cart__toggler">
                          <span className="icon-shopping-cart" />
                        </Link>
                      </div>
                      <div className="contact-box">
                        <div className="icon">
                          <span className="icon-phone-call-2" />
                        </div>
                        <div className="text">
                          <p>Call 24x7</p>
                          <Link to="tel:123456789">+91 8817242006</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {/* <div className="stricky-header stricked-menu main-menu">
          <div className="sticky-header__content"></div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default Hadear;
