import React from 'react'
import { Link } from "react-router-dom";


const TreesArrangement = () => {
  return (
    <>
      <section
        className="page-header clearfix"
        style={{
          backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)",
        }}
      >
        <div className="container">
          <div className="page-header__inner text-center clearfix">
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Trees Arrangement</li>
            </ul>
            <h2> Trees Arrangement</h2>
          </div>
        </div>
      </section>

      <section className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4">
              <div className="services-details__sidebar">
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-services wow fadeInUp animated animated animated"
                    data-wow-delay="0.1s"
                    data-wow-duration="1200m"
                  >
                    <div className="title">
                      <h3>All Services</h3>
                    </div>
                    <ul>
                      <li>
                        <Link to="/treesarrangement">
                          Trees Arrangement <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/plantation">
                          Plantation <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/pottedplantarrangements">
                          Pottedv Plant Arrangements{" "}
                          <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/treesarrangement">
                          Trees Arrangement <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-contact-box text-center wow fadeInUp animated animated animated"
                    data-wow-delay="0.3s"
                    data-wow-duration="1200m"
                    style={{
                      backgroundImage:
                        "url(assets/images/resources/services-details-contact-box-bg.jpg)",
                    }}
                  >
                    <div className="icon">
                      <span className="icon-phone-call-2" />
                    </div>
                    <div className="title">
                      <h2>Home Delavery</h2>
                    </div>
                    <p className="phone">
                      <Link className="number" to="tel:+91 8817242006">
                        +91 8817242006
                      </Link>
                    </p>
                    <p className="text-center">Call Us 24x7</p>
                  </div>
                </div>
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-btn text-center wow fadeInUp animated animated animated"
                    data-wow-delay="0.5s"
                    data-wow-duration="1200m"
                  >
                    <Link to="/gallery" className="thm-btn">
                      <span className="icon-pdf" /> VIEW GALLERY
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="services-details__content">
                <div className="services-details__content-img">
                  <img
                    src="assets/images/services/Trees Arrangement1.jpg"
                    alt
                  />
                </div>
                <div className="services-details__content-icon">
                  <span className="icon-harvest" />
                </div>
                <h2 className="services-details__content-title">
                  Trees Arrangement About
                </h2>
                <p className="services-details__content-text1">
                  MAY not the want of symmetry or the “anyhowness” of the
                  arrangement of the branches of trees serve some highly useful
                  purpose? May it not help to prevent the trees being overturned
                  in the highest winds by the want of synchronism in the motions
                  of the branches? I have never seen or heard of such an idea
                  and it may be open to serious objections.
                </p>
                <p className="services-details__content-text2">
                  But some time ago I watched the branches of a large plane
                  tree, still partially in leaf, during a high gale, and it
                  seemed incredible the tree could stand, but for the fact that
                  whilst one large limb was swaying one way, another would be
                  swaying the opposite way, and so on, all plunging and bending
                  anyhow, with no two in harmony. Some of the larger limbs would
                  swoop down as others bounded up in a sudden gust, and some
                  swaying laterally with the wind would be balanced by others at
                  another part of the tree swaying against the wind.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features-one features-one--services clearfix">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 wow fadeInUp animated animated">
              <div className="features-one__single">
                <div className="features-one__single-img">
                  <img src="assets/images/services/tree1.jpg" alt />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 wow fadeInUp animated animated">
              <div className="features-one__single">
                <div className="features-one__single-img">
                  <img src="assets/images/services/tree2.jpg" alt />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 wow fadeInUp animated animated">
              <div className="features-one__single">
                <div className="features-one__single-img">
                  <img src="assets/images/services/tree3.jpg" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TreesArrangement