import React from 'react'
import Hadear from './layout/Hadear'
import { Outlet } from 'react-router-dom'
import Footer from './layout/Footer'
import Hadear1 from './layout/Hadear1'

const MainLayOut = () => {
  return (
      <>
      
      <Hadear />
      {/* <Hadear1 /> */}
          <Outlet />
          <Footer/>

      </>
  )
}

export default MainLayOut