import React from 'react'
import { Link } from "react-router-dom";


const PottedPlantArrangements = () => {
  return (
    <>
      <section
        className="page-header clearfix"
        style={{
          backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)",
        }}
      >
        <div className="container">
          <div className="page-header__inner text-center clearfix">
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Potted Plant Arrangements</li>
            </ul>
            <h2> Potted Plant Arrangements</h2>
          </div>
        </div>
      </section>

      <section className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4">
              <div className="services-details__sidebar">
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-services wow fadeInUp animated animated animated"
                    data-wow-delay="0.1s"
                    data-wow-duration="1200m"
                  >
                    <div className="title">
                      <h3>All Services</h3>
                    </div>
                    <ul>
                      <li>
                        <Link to="/pottedplantarrangements">
                          Potted Plant Arrangements{" "}
                          <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/plantation">
                          Plantation <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/pottedplantarrangements">
                          Pottedv Plant Arrangements{" "}
                          <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/treesarrangement">
                          Trees Arrangement <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-contact-box text-center wow fadeInUp animated animated animated"
                    data-wow-delay="0.3s"
                    data-wow-duration="1200m"
                    style={{
                      backgroundImage:
                        "url(assets/images/resources/services-details-contact-box-bg.jpg)",
                    }}
                  >
                    <div className="icon">
                      <span className="icon-phone-call-2" />
                    </div>
                    <div className="title">
                      <h2>Home Delavery</h2>
                    </div>
                    <p className="phone">
                      <Link className="number" to="tel:+91 8817242006">
                        +91 8817242006
                      </Link>
                    </p>
                    <p className="text-center">Call Us 24x7</p>
                  </div>
                </div>
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-btn text-center wow fadeInUp animated animated animated"
                    data-wow-delay="0.5s"
                    data-wow-duration="1200m"
                  >
                    <Link to="/gallery" className="thm-btn">
                      <span className="icon-pdf" /> VIEW GALLERY
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="services-details__content">
                <div className="services-details__content-img">
                  <img
                    src="assets/images/services/PottedPlantArrangements.jpg"
                    alt
                  />
                </div>
                <div className="services-details__content-icon">
                  <span className="icon-harvest" />
                </div>
                <h2 className="services-details__content-title">
                  Potted Plant Arrangements About
                </h2>
                <p className="services-details__content-text1">
                  If you have a cluster of potted plants, how do you know if
                  they all fit well together? Potted plants might not remind you
                  of a sculpture, but that’s exactly how you can think of them
                  to help decide if your collection of potted plants works or
                  not.
                </p>
                <p className="services-details__content-text2">
                  In order to view your cluster of potted plants as a sculpture,
                  imagine if they were all attached, would the design still work
                  or would it appear awkward? While all plants and planters can
                  vary, overall they need to flow into one cohesive form when
                  arranged together.
                </p>
              </div>
              <br/>
              <div className="services-details__content-img">
                <img
                  src="assets/images/services/PottedPlantArrangements1.jpg"
                  alt
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PottedPlantArrangements