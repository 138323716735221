import React from "react";

const HomeAbout = () => {
  return (
    <>
      <section className="about-two">
        <div className="about-two__bg">
          <img src="assets/images/backgrounds/about-v2-bg.png" alt />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="about-two__img-box clearfix">
                <div className="about-two__img-box__shape" />
                <div className="about-two__img-box-img1">
                  <div className="about-two__img-box-img1-inner">
                    <img src="assets/images/about/about-v2-img1.jpg" alt />
                  </div>
                </div>
                <div className="about-two__img-box-img2">
                  <div className="about-two__img-box-img2__shape" />
                  <div className="logo">
                    <img src="assets/images/resources/best.png" alt />
                  </div>
                  <div className="about-two__img-box-img2-inner">
                    <img src="assets/images/about/about-v2-img2.jpg" alt />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-two__content-box">
                <div className="sec-title">
                  <div className="icon">
                    <img
                      src="assets/images/resources/sec-title-icon1.png"
                      alt
                    />
                  </div>
                  <h2 className="sec-title__title">About Us</h2>
                </div>
                <p className="about-two__content-box-text">
                  Plantage Organic Agriculture was founded in 2018 and has
                  continued to grow, building a reputation to deliver the
                  highest standards of quality for Plantation & Garden design,
                  development and maintenance.
                </p>
                <p className="about-two__content-box-text mt-lg-4">
                  Plantage Organic Agriculture is guided through its vision to
                  Plant Happiness for all the mankind to own it. We do so by
                  creating an umbrella of solutions for all the needs related to
                  horticulture and landscaping.
                </p>
                <p className="about-two__content-box-text mt-lg-4">
                  Our goal is to achieve the customer’s trust by meeting their
                  expectations and sometimes even exceed them. We work with
                  ethics, reliability, and integrity in regard to budget,
                  schedule, quality workmanship, communication, and safety
                  responsibilities.
                </p>
                <p className="about-two__content-box-text mt-lg-4">
                  With our well-experienced team we can design and build a
                  distinctive soft landscape outdoors as well as small indoor
                  gardens with equal efficiency, cost-effectiveness and
                  sustainable customer satisfaction.
                </p>

                {/* <div className="about-two__content-box-list">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="about-two__content-box-list-single">
                        <ul>
                          <li>
                            <div className="icon">
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="text">
                              <p>Lorem is not random text</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="text">
                              <p>Evolved over the years</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="text">
                              <p>Generator on the Internet</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="about-two__content-box-list-single">
                        <ul>
                          <li>
                            <div className="icon">
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="text">
                              <p>If you are going to use</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="text">
                              <p>Making this the first true</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="text">
                              <p>Over 50 years experience</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="about-two__progress">
                  <div className="about-two__progress-single">
                    <h4 className="about-two__progress-title">Agriculture</h4>
                    <div className="bar">
                      <div className="bar-inner count-bar" data-percent="78%">
                        <div className="count-text">78%</div>
                      </div>
                    </div>
                  </div>
                  <div className="about-two__progress-single">
                    <h4 className="about-two__progress-title">Organic</h4>
                    <div className="bar">
                      <div className="bar-inner count-bar" data-percent="67%">
                        <div className="count-text">67%</div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="about-two__author">
                  <h2>
                    Mike Hardson<span> CEO Agriox</span>
                  </h2>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
