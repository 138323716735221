import React from "react";
import { Link } from "react-router-dom";

const Gallery = () => {
  return (
    <>
      <section
        className="page-header clearfix"
        style={{
          backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)",
        }}
      >
        <div className="container">
          <div className="page-header__inner text-center clearfix">
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Gallery</li>
            </ul>
            <h2>Our Gallery</h2>
          </div>
        </div>
      </section>

      <section className="projects-one projects-one--two projects-one--two--projects">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="projects-one--two--projects__wrapper">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/1.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/2.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/25.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/4.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/5.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/6.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/7.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/8.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/9.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/10.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/11.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/13.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/16.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/17.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/18.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/19.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/20.JPEG"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
