import React from "react";

const VisionValues = () => {
  return (
    <>
      <section className="faq-one faq-one--faq">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="faq-one__accordions">
                <div className="accrodion-grp" data-grp-name="faq-one-accrodion">
                  <div className="accrodion active  wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1000ms">
                    <div className="accrodion-title">
                      <h4 style={{textAlign:"center"}}>Our Mission</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>In order to help farming communities boost productivity, save expenses, and lower risk, Plantage Organic Agriculture Pvt.ltd. is dedicated to offering creative and sustainable solutions. To offer state-of-the-art tissue culture technologies and research & development in order to produce excellent horticultural and agroforestry plants. </p>
                        <br/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="faq-one__accordions">
                <div className="accrodion-grp2" data-grp-name="faq-one-accrodion">
                  <div className="accrodion active  wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1000ms">
                    <div className="accrodion-title">
                      <h4 style={{textAlign:"center"}}> Our Vision</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>to support the development of a more robust agricultural sector by offering rural communities reasonably priced alternatives. To be a pioneer in sustainable agriculture, providing farmers and landowners with superior, genetically enhanced horticultural and agroforestry plants, organic fertilizers, and cutting-edge agricultural solutions that increase yields while protecting the environment.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default VisionValues;
