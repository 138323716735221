import React from "react";

const SmallStory = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <section className="story-one">
          <div className="story-one__shape" />
          <div className="auto-container">
            <div className="row">
              <div className="col-xl-6">
                <div
                  className="story-one__img"
                  style={{
                    backgroundImage:
                      "url(assets/images/resources/Food.webp)",
                  }}
                >
                  <div className="story-one__img__line float-bob-y" />
                  {/* <h3>
                    #Agriculture <br />
                    Matters
                  </h3> */}
                </div>
              </div>
              <div className="col-xl-6">
                <div className="story-one__counters">
                  <div className="story-one__bg">
                    <img src="assets/images/backgrounds/story-v1-bg.png" alt />
                  </div>
                  <div className="sec-title">
                    <div className="icon">
                      <img
                        src="assets/images/resources/sec-title-icon1.png"
                        alt
                      />
                    </div>
                    <span className="sec-title__tagline">
                      Plantage Organic Agriculture - 2018
                    </span>
                    <h2 className="sec-title__title">Small Agricultue Story</h2>
                  </div>
                  <p className="story-one__counters-text">
                    We are committed to providing our customers with exceptional
                    service while offering our employees the best training.
                  </p>
                  <div className="story-one__counters-box">
                    <ul>
                      <li
                        className="story-one__counters-box-single text-center wow slideInUp animated"
                        data-wow-delay="0.1s"
                        data-wow-duration="1500ms"
                      >
                        <h3 className="odometer" data-count={869}>
                          1500+
                        </h3>
                        <p className="story-one__counters-box-single-text">
                          Projects 
                          Completed
                        </p>
                      </li>
                      <li
                        className="story-one__counters-box-single text-center wow slideInUp animated"
                        data-wow-delay="0.3s"
                        data-wow-duration="1500ms"
                      >
                        <h3 className="odometer" data-count={683}>
                          1500+
                        </h3>
                        <p className="story-one__counters-box-single-text">
                          Satisfied
                          Customers
                        </p>
                      </li>
                      <li
                        className="story-one__counters-box-single text-center wow slideInUp animated"
                        data-wow-delay="0.5s"
                        data-wow-duration="1500ms"
                      >
                        <h3 className="odometer" data-count={975}>
                          10+
                        </h3>
                        <p className="story-one__counters-box-single-text">
                          Products
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SmallStory;
