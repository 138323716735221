import React from "react";

const Testimonials = () => {
  return (
    <>
      <section className="testimonials-page">
        <div className="container">
          <div className="row">
            <h2 className="sec-title__title text-center">Client Messages</h2>

            <div className="col-md-6 col-lg-4">
              <div className="testimonials-one__single">
                <p className="testimonials-one__single-text">
                  First time purchase & packaging was good & service was also
                  good. good products. Thanks!
                </p>
                <div className="testimonials-one__single-client-info">
                  <div className="testimonials-one__single-client-info-img">
                    <div className="testimonials-one__single-client-info-img-inner">
                      <img src="assets/images/testimonial/icon.png" alt />
                    </div>
                    <div className="icon">
                      <span className="icon-right-quotation-mark" />
                    </div>
                  </div>
                  <div className="testimonials-one__single-client-info-title">
                    <h4>Shubham Kumar</h4>
                    <p>Customer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="testimonials-one__single">
                <p className="testimonials-one__single-text">
                  Very good quality nice delivery and sufficient price. good
                  Service!
                </p>
                <div className="testimonials-one__single-client-info">
                  <div className="testimonials-one__single-client-info-img">
                    <div className="testimonials-one__single-client-info-img-inner">
                      <img src="assets/images/testimonial/icon.png" alt />
                    </div>
                    <div className="icon">
                      <span className="icon-right-quotation-mark" />
                    </div>
                  </div>
                  <div className="testimonials-one__single-client-info-title">
                    <h4>Anurag Patel</h4>
                    <p>Customer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="testimonials-one__single">
                <p className="testimonials-one__single-text">
                  good product. Low price good product. good service free
                  delivery!
                </p>
                <div className="testimonials-one__single-client-info">
                  <div className="testimonials-one__single-client-info-img">
                    <div className="testimonials-one__single-client-info-img-inner">
                      <img src="assets/images/testimonial/icon.png" alt />
                    </div>
                    <div className="icon">
                      <span className="icon-right-quotation-mark" />
                    </div>
                  </div>
                  <div className="testimonials-one__single-client-info-title">
                    <h4>Yogesh Dwivedi</h4>
                    <p>Customer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
};

export default Testimonials;
