import React from 'react'
import { Link } from "react-router-dom";


const HomeServices = () => {
  return (
    <>
      <section className="services-one">
        <div
          className="services-one__bg wow slideInDown"
          data-wow-delay="100ms"
          data-wow-duration="2500ms"
        />
        <div className="container">
          <div className="sec-title text-center">
            <div className="icon">
              <img src="assets/images/resources/sec-title-icon1.png" alt />
            </div>

            <h2 className="sec-title__title">Services</h2>
            <span className="sec-title__tagline mt-lg-5">
              We provide Many green services for our clients like Plantation &
              Garden Design, Potted Plant Arrangements, Commercial Garden,
              Vertical Garden, Artificial Plants & Trees Arrangement, Terrace
              Garden, Balcony Garden etc Different types of Services are offered
              by us depending on the requirement and the budget of our clients.
              Services We Offered
            </span>
          </div>
          <div className="row">
            <div
              className="col-xl-3 col-lg-6 wow fadeInLeft"
              data-wow-delay="0ms"
              data-wow-duration="1000ms"
            >
              <div className="services-one__single">
                <div className="services-one__single-img">
                  <div className="services-one__single-img-inner">
                    <img src="assets/images/services/GARDENS.jpg" alt />
                  </div>
                </div>
                <div className="services-one__single-content text-center">
                  <div className="services-one__single-img-icon">
                    <span className="icon-agriculture" />
                  </div>
                  <h3>
                    <Link to="">Garden Design</Link>
                  </h3>
                  <p>
                    We offer a range of design services that can be tailored to
                    your needs. Whether you are looking for inspiration and
                    advice, a revamp of
                  </p>
                  <Link to="/gardendesign" className="read-more-btn">
                    <span className="icon-right-arrow-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6 wow fadeInLeft"
              data-wow-delay="100ms"
              data-wow-duration="1000ms"
            >
              <div className="services-one__single">
                <div className="services-one__single-img">
                  <div className="services-one__single-img-inner">
                    <img src="assets/images/services/Plantation.jpg" alt />
                  </div>
                </div>
                <div className="services-one__single-content text-center">
                  <div className="services-one__single-img-icon">
                    <span className="icon-harvest" />
                  </div>
                  <h3>
                    <Link to="#">Plantation</Link>
                  </h3>
                  <p>
                    we offer Plantation consultancy and construction services
                    for fountain water features. Our combined experience in
                    design and construction is wide
                  </p>
                  <Link to="/plantation" className="read-more-btn">
                    <span className="icon-right-arrow-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6 wow fadeInRight"
              data-wow-delay="0ms"
              data-wow-duration="1000ms"
            >
              <div className="services-one__single">
                <div className="services-one__single-img">
                  <div className="services-one__single-img-inner">
                    <img
                      src="assets/images/services/Potted Plant Arrangements.jpg"
                      alt
                    />
                  </div>
                </div>
                <div className="services-one__single-content text-center">
                  <div className="services-one__single-img-icon">
                    <span className="icon-growth" />
                  </div>
                  <h3>
                    <Link to="#">Potted Plant Arrangements</Link>
                  </h3>
                  <p>
                    we offers a design and build service for Potted Plant
                    Arrangements.
                  </p>
                  <Link to="/pottedplantarrangements" className="read-more-btn">
                    <span className="icon-right-arrow-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6 wow fadeInRight"
              data-wow-delay="100ms"
              data-wow-duration="1000ms"
            >
              <div className="services-one__single">
                <div className="services-one__single-img">
                  <div className="services-one__single-img-inner">
                    <img
                      src="assets/images/services/Trees Arrangement.jpg"
                      alt
                    />
                  </div>
                </div>
                <div className="services-one__single-content text-center">
                  <div className="services-one__single-img-icon">
                    <span className="icon-dairy-products" />
                  </div>
                  <h3>
                    <Link to="#">Trees Arrangement</Link>
                  </h3>
                  <p>
                    Trees Arrangement are simply one of the best ways of
                    creating a stylish garden space with none of the hassle of
                  </p>
                  <Link to="/treesarrangement" className="read-more-btn">
                    <span className="icon-right-arrow-2" />
                  </Link>
                </div>
              </div>
            </div>
            {/*End Single Services One*/}
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeServices