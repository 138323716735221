import React from "react";

const Facility = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <section className="cta-two">
          <div className="cta-two__shape" />
          <div className="container">
            <div className="cta-two__inner">
              <div className="row">
                <div className="col-lg-6 d-flex align-items-center">
                  <h2 className="cta-two__title">Work Facility</h2>
                </div>
                <div className="col-lg-6">
                  <div className="cta-two__right">
                    <ul className="cta-two__list list-unstyled">
                      <li>
                        <i className="fa fa-check-circle" />
                        Home Delavery
                      </li>
                    </ul>
                    <img src="assets/images/resources/cta-two-1-1.png" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Facility;
