import React from 'react'

const CoreValues = () => {
    return (
        <>
            <section className="about-two">
                <div className="about-two__bg"><img src="assets/images/backgrounds/about-v2-bg.png" alt /></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="about-two__img-box clearfix">
                                <div className="about-two__img-box__shape" />
                                <div className="about-two__img-box-img1">
                                    <div className="about-two__img-box-img1-inner">
                                        <img src="assets/images/about/rs=w_1240,cg_true.jpg" alt />
                                    </div>
                                </div>
                                <div className="about-two__img-box-img2">
                                    <div className="about-two__img-box-img2__shape" />
                                    <div className="logo"><img src="assets/images/resources/about-v2-logo.png" alt /></div>
                                    <div className="about-two__img-box-img2-inner">
                                        {/* <img src="assets/images/about/about-v2-img2.jpg" alt /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="about-two__content-box">
                                <div className="sec-title">
                                    <div className="icon">
                                        <img src="assets/images/resources/sec-title-icon1.png" alt />
                                    </div>
                                    <span className="sec-title__tagline">Plantage Organic Agriculture</span>
                                    <h2 className="sec-title__title"> Core Values</h2>
                                </div>
                                <div className="about-two__content-box-list">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                            <div className="about-two__content-box-list-single">
                                                <ul>
                                                    <li>
                                                        <div className="icon">
                                                            {/* <i className="fa fa-check-circle" aria-hidden="true" /> */}
                                                        </div>
                                                        <div className="text">
                                                            <p> <b>Team Work:-</b>As a team, we actively participate in overcoming challenges.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="icon">
                                                        </div>
                                                        <div className="text">
                                                            <p> <b>Innovation:-</b>We push ourselves continuously to come up with fresh, more effective ways to convey ideals.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="icon">
                                                        </div>
                                                        <div className="text">
                                                            <p> <b>Integrity:-</b>We operate in an ethical and open manner, and we provide to do what is right.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="icon">
                                                        </div>
                                                        <div className="text">
                                                            <p> <b>Listen & Respect:-</b>We take criticism seriously and act upon it. We promote candid and open conversation.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="icon">
                                                        </div>
                                                        <div className="text">
                                                            <p> <b>People Development:-</b>People are essential to the company's success. We will create an atmosphere that allows both customers and employees to reach their full potential.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="icon">
                                                        </div>
                                                        <div className="text">
                                                            <p> <b>Excellence:-</b>To satisfy our clients by offering high-quality goods and services.
                                                            </p>
                                                        </div>
                                                    </li>
                                                   
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CoreValues