import React from "react";
import { Link } from "react-router-dom";

const GardenDesign = () => {
  return (
    <>
      <section
        className="page-header clearfix"
        style={{
          backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)",
        }}
      >
        <div className="container">
          <div className="page-header__inner text-center clearfix">
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Garden Design</li>
            </ul>
            <h2> Garden Design</h2>
          </div>
        </div>
      </section>

      <section className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4">
              <div className="services-details__sidebar">
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-services wow fadeInUp animated animated animated"
                    data-wow-delay="0.1s"
                    data-wow-duration="1200m"
                  >
                    <div className="title">
                      <h3>All Services</h3>
                    </div>
                    <ul>
                      <li>
                        <Link to="/gardendesign">
                          Garden Design <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/plantation">
                          Plantation <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/pottedplantarrangements">
                          Pottedv Plant Arrangements{" "}
                          <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/treesarrangement">
                          Trees Arrangement <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-contact-box text-center wow fadeInUp animated animated animated"
                    data-wow-delay="0.3s"
                    data-wow-duration="1200m"
                    style={{
                      backgroundImage:
                        "url(assets/images/resources/services-details-contact-box-bg.jpg)",
                    }}
                  >
                    <div className="icon">
                      <span className="icon-phone-call-2" />
                    </div>
                    <div className="title">
                      <h2>Home Delavery</h2>
                    </div>
                    <p className="phone">
                      <Link className="number" to="tel:+91 8817242006">
                        +91 8817242006
                      </Link>
                    </p>
                    <p className="text-center">Call Us 24x7</p>
                  </div>
                </div>
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-btn text-center wow fadeInUp animated animated animated"
                    data-wow-delay="0.5s"
                    data-wow-duration="1200m"
                  >
                    <Link to="/gallery" className="thm-btn">
                      <span className="icon-pdf" /> VIEW GALLERY
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="services-details__content">
                <div className="services-details__content-img">
                  <img src="assets/images/resources/GardenDesign.jpg" alt />
                </div>
                <div className="services-details__content-icon">
                  <span className="icon-harvest" />
                </div>
                <h2 className="services-details__content-title">
                  Garden About
                </h2>
                <p className="services-details__content-text1">
                  We offer a range of design services that can be tailored to
                  your needs. Whether you are looking for inspiration and
                  advice, a revamp of your existing planting or a complete
                  transformation of your garden, we are more than happy to help
                  and advise you on the services you may need. The full garden
                  design service outlined above will guide you through the
                  design and build process – from inspiration to implementation!
                </p>
                <p className="services-details__content-text2">
                  our consultation meeting is the time to discuss exciting ideas
                  which will form the basis of the design brief and to discuss
                  your budget. During the meeting I will listen carefully to
                  your ideas and together we will explore how you would like to
                  use your garden and the particular style or feel you would
                  like to create. If you have any images of gardens, textures or
                  planting styles that you would like considered in the design,
                  now is the perfect time to show me. I will bring along my
                  portfolio and explain the garden design process to you, so you
                  have a full understanding of the different stages involved.
                </p>
                <p className="services-details__content-text2 mt-lg-5">
                  we walk around your garden and assess it from a design
                  perspective – looking at the possibilities and limitations of
                  the site, considering the views, aspect, levels, soil
                  structure and access. Following the meeting I will draw up a
                  brief for the design of the garden based on our discussions
                  along with a quote for the design services require.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features-one features-one--services clearfix">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 wow fadeInUp animated animated">
              <div className="features-one__single">
                <div className="features-one__single-img">
                  <img src="assets/images/services/1.jpg" alt />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 wow fadeInUp animated animated">
              <div className="features-one__single">
                <div className="features-one__single-img">
                  <img src="assets/images/services/2.jpg" alt />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 wow fadeInUp animated animated">
              <div className="features-one__single">
                <div className="features-one__single-img">
                  <img src="assets/images/services/3.jpg" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GardenDesign;
