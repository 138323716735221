import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";


const Contact = () => {

  const [name, setName] = useState("");
  const [mail, setmail] = useState("");
  const [massages, setMassages] = useState("");
  const [number, setNumber] = useState("");

  const nameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };

  const mailChange = (e) => {
    const value = e.target.value;
    setmail(value);
  };
  const massagesChange = (e) => {
    const value = e.target.value;
    setMassages(value);
  };
  const numberChange = (e) => {
    const value = e.target.value;
    setNumber(value);
  };


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(

        "service_ttnco5u",
        // "template_dm1w6sk",
        "#myForm",
        "FhLtO02IwcAuGDfR1"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          if (response.text === "OK") {
            setName("");
            setmail("");
            setMassages("");
            setNumber("");

          }
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };


  return (
    <>
      <div>
        <section
          className="page-header clearfix"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/page-header-bg.jpg)",
          }}
        >
          <div className="container">
            <div className="page-header__inner text-center clearfix">
              <ul className="thm-breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Contact</li>
              </ul>
              <h2>Contact Us</h2>
            </div>
          </div>
        </section>

        <section className="contact-page">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4">
                <div className="contact-page__left">
                  <div className="sec-title">
                    <div className="icon">
                      <img
                        src="assets/images/resources/sec-title-icon1.png"
                        alt
                      />
                    </div>
                    <span className="sec-title__tagline">Contact now</span>
                    <h2 className="sec-title__title">
                      Get in Touch <br />
                      with Us
                    </h2>
                  </div>
                  <p className="contact-page__left-text">
                    We are committed to providing our customers with exceptional
                    service while offering our employees the best training.
                  </p>
                  <div className="contact-page__social-link">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-pinterest-p" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8">
                <div className="contact-page__right">
                  <form
                    action="assets/inc/sendemail.php"
                    className="comment-one__form contact-form-validated"
                    noValidate="novalidate"
                    onSubmit={sendEmail} id="myForm"
                  >
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <input
                            type="text"
                            placeholder="Your name"
                            name="from_name"
                            onChange={(e) => nameChange(e)}
                            required
                            value={name}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <input
                            type="email"
                            placeholder="Email address"
                            name='email'
                            onChange={(e) => mailChange(e)}
                            value={mail}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12">
                        <div className="comment-form__input-box">
                          <input
                            type="text"
                            placeholder="Phone number"
                            name="number"
                            onChange={(e) => numberChange(e)}
                            value={number}
                            required
                          />
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12">
                        <div className="comment-form__input-box">
                          <textarea
                            onChange={(e) => massagesChange(e)}
                            name='massage'
                            value={massages}
                            required
                            placeholder="Write message"
                            defaultValue={""}
                          />
                        </div>
                        <a href="">
                          <button
                            type="submit"
                            className="thm-btn comment-form__btn"
                          >
                            Send a message
                          </button>
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-page-google-map container">
          <div style={{ maxWidth: '100%', listStyle: 'none', transition: 'none', overflow: 'hidden', width: 1200, height: 350 }}><div id="gmap-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=Near+by+Rituraj+Degree+College,oo+Jayram+Nager+SMS+Colony,+Fatehpur(U.P)+212601&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="googlecoder" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="enable-maps-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#gmap-canvas img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}" }} /></div>
        </section>



        <section className="contact-page__contact-info clearfix">
          <div className="auto-container">
            <div className="row">
              <div className="col-xl-12">
                <div className="contact-page__contact-info-wrapper">
                  {/* <div className="contact-page__contact-info-title">
                    <h2>Get in Touch</h2>
                  </div> */}
                  <div className="contact-page__contact-info-list">
                    <ul>
                      <li>
                        <div className="icon">
                          <span className="icon-map" />
                        </div>
                        <div className="title">
                          <span>Visit Our Store</span>
                          <p>Near by Rituraj Degree College,oo Jayram Nager SMS Colony, <br />Fatehpur(U.P) 212601</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="icon-email-1" />
                        </div>
                        <div className="title">
                          <span>Send Email</span>
                          <p><a href="mailto:Poagriculture@gmail.com">Poagriculture@gmail.com</a></p>
                        </div>
                      </li>
                      <li>
                        <div className="icon phone">
                          <span className="icon-phone-call-2" />
                        </div>
                        <div className="title">
                          <span>Call Anytime</span>
                          <p><a href="tel:+918817242006">+91 8817242006</a></p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>




      </div>
    </>
  );
};

export default Contact;
