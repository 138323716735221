import React from "react";
import { Link } from "react-router-dom";

const Plantation = () => {
  return (
    <>
      <section
        className="page-header clearfix"
        style={{
          backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)",
        }}
      >
        <div className="container">
          <div className="page-header__inner text-center clearfix">
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Plantation</li>
            </ul>
            <h2> Plantation</h2>
          </div>
        </div>
      </section>

      <section className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4">
              <div className="services-details__sidebar">
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-services wow fadeInUp animated animated animated"
                    data-wow-delay="0.1s"
                    data-wow-duration="1200m"
                  >
                    <div className="title">
                      <h3>All Services</h3>
                    </div>
                    <ul>
                      <li>
                        <Link to="/plantation">
                          Plantation <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/gardendesign">
                          Garden Design <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/pottedplantarrangements">
                          Pottedv Plant Arrangements{" "}
                          <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/treesarrangement">
                          Trees Arrangement <i className="fa fa-angle-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-contact-box text-center wow fadeInUp animated animated animated"
                    data-wow-delay="0.3s"
                    data-wow-duration="1200m"
                    style={{
                      backgroundImage:
                        "url(assets/images/resources/services-details-contact-box-bg.jpg)",
                    }}
                  >
                    <div className="icon">
                      <span className="icon-phone-call-2" />
                    </div>
                    <div className="title">
                      <h2>Home Delavery</h2>
                    </div>
                    <p className="phone">
                      <Link className="number" to="tel:+91 8817242006">
                        +91 8817242006
                      </Link>
                    </p>
                    <p className="text-center">Call Us 24x7</p>
                  </div>
                </div>
                <div className="services-details__sidebar-single">
                  <div
                    className="services-details__sidebar-single-btn text-center wow fadeInUp animated animated animated"
                    data-wow-delay="0.5s"
                    data-wow-duration="1200m"
                  >
                    <Link to="/gallery" className="thm-btn">
                      <span className="icon-pdf" /> VIEW GALLERY
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="services-details__content">
                <div className="services-details__content-img">
                  <img src="assets/images/services/plants.jpg" alt />
                </div>
                <div className="services-details__content-icon">
                  <span className="icon-harvest" />
                </div>
                <h2 className="services-details__content-title">
                  Plantation About
                </h2>
                <p className="services-details__content-text1">
                  A plantation is an agricultural estate, generally centered on
                  a plantation house, meant for farming that specializes in cash
                  crops, usually mainly planted with a single crop, with perhaps
                  ancillary areas for vegetables for eating and so on. The crops
                  that are grown include cotton, cannabis, coffee, tea, cocoa,
                  sugar cane, opium, sisal, oil seeds, oil palms, fruits, rubber
                  trees and forest trees. Protectionist policies and natural
                  comparative advantage have sometimes contributed to
                  determining where plantations are located.
                </p>
                <p className="services-details__content-text2">
                  Among the earliest examples of plantations were the latifundia
                  of the Roman Empire, which produced large quantities of grain,
                  wine, and olive oil for export. Plantation agriculture
                  proliferated with the increase in international trade and the
                  development of a worldwide economy that followed the expansion
                  of European colonialism.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features-one features-one--services clearfix">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 wow fadeInUp animated animated">
              <div className="features-one__single">
                <div className="features-one__single-img">
                  <img src="assets/images/services/plantion1.jpg" alt />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 wow fadeInUp animated animated">
              <div className="features-one__single">
                <div className="features-one__single-img">
                  <img src="assets/images/services/plantion2.jpeg" alt />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 wow fadeInUp animated animated">
              <div className="features-one__single">
                <div className="features-one__single-img">
                  <img src="assets/images/services/plantion3.jpg" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Plantation;
