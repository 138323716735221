import React from "react";
import HomeBanner from "../components/home/HomeBanner";
import VisionValues from "../components/home/VisionValues";
import HomeAbout from "../components/home/HomeAbout";
import Testimonials from "../components/home/Testimonials";
import HomeServices from "../components/home/HomeServices";
import Facility from "../components/home/Facility";
import SmallStory from "../components/home/SmallStory";
import RecentWorks from "../components/home/RecentWorks";
import CoreValues from "../components/home/CoreValues";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <HomeAbout />
      <VisionValues />
      <CoreValues />
      <Facility />
      <HomeServices />
      <RecentWorks />
      <SmallStory />
      <Testimonials />
    </>
  );
};

export default Home;
