import React from "react";
import { Link } from "react-router-dom";
import WhatsappIcon from "../../pages/WhatsappIcon";

const Footer = () => {
  return (
    <>
      <WhatsappIcon />


      <div className="">
        <footer
          className="text-center text-lg-start text-white"
          style={{ backgroundColor: "#334b35" }}
        >

          <section className>
            <div className="container text-center text-md-start">
              <div className="row">
                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                  <h6
                    className="text-uppercase fw-bold mt-lg-5"
                    style={{ color: "#f1cf69" }}
                  >
                    <br />
                    <br />
                    About Us
                  </h6>
                  <br />
                  <p className="mt-lg-5">
                    Plantage Organic Agriculture was founded in 2018 and has
                    continued to grow, building a reputation to deliver the
                    highest standards of quality for Plantation & Garden design,
                    development and maintenance.
                  </p>

                  {/* <form className="subscribe-form" action="#">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                    />
                    <button type="submit">Go</button>
                  </form> */}
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  <h6
                    className="text-uppercase fw-bol mt-lg-5"
                    style={{ color: "#f1cf69" }}
                  >
                    <br />
                    <br />
                    <b>Explore</b>
                    <br />
                    <br />
                    <br />
                  </h6>

                  <p className="mt-lg-5">
                    <Link to="/" className="text-white">
                      Home
                    </Link>
                  </p>
                  <p>
                    <Link to="/about" className="text-white">
                      About
                    </Link>
                  </p>
                  <p>
                    <Link to="/gardendesign" className="text-white">
                      Services
                    </Link>
                  </p>
                  <p>
                    <Link to="/plantation" className="text-white">
                      Plantation
                    </Link>
                  </p>
                  <p>
                    <Link to="/gardendesign" className="text-white">
                      Garden Design
                    </Link>
                  </p>

                  <p>
                    <Link to="/treesarrangement" className="text-white">
                      Trees Arrangement
                    </Link>
                  </p>
                </div>
                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 link">
                  <h6
                    className="text-uppercase fw-bold mt-lg-5"
                    style={{ color: "#f1cf69" }}
                  >
                    <br />
                    <br />
                    Useful links
                  </h6>
                  <br />
                  <p className="mt-lg-5">
                    <Link to="/gallery" className="text-white">
                      Gallery
                    </Link>
                  </p>
                  <p className="">
                    <Link to="/mahoganyplants" className="text-white">
                      Mahogany Plants
                    </Link>
                  </p>
                  <p className="">
                    <Link to="/mangoplant" className="text-white">
                      Mango Plant
                    </Link>
                  </p>
                  <p className="">
                    <Link to="/gouvaplant" className="text-white">
                      Gouva Plant
                    </Link>
                  </p>
                  <p>
                    <Link to="/contact" className="text-white" style={{}}>
                      Career
                    </Link>
                  </p>
                  <p>
                    <Link to="/contact" className="text-white">
                      Contact
                    </Link>
                  </p>
                </div>
                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                  <h6
                    className="text-uppercase fw-bold mt-lg-5"
                    style={{ color: "#f1cf69" }}
                  >
                    <br />
                    <br />
                    Contact
                  </h6>
                  <br />
                  <p className="mt-lg-5">
                    <i className="fas fa-home mr-3" /> Near by Rituraj Degree College,oo Jayram Nager SMS Colony, Fatehpur(U.P) 212601
                  </p>
                  <p>
                    {/* <i className="fas fa-envelope mr-3" />{" "}
                    poagriculture@gmail.com */}
                    <a
                      className="fas fa-envelope mail"
                      href="mailto:Poagriculture@gmail.com"
                    > Poagriculture@gmail.com
                    </a>
                  </p>
                  <p>
                    <i className="fas fa-phone mr-3" /> 
                    <a href="tel:+918817242006"> +91 8817242006</a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
          >
            2024 © Plantage Organic Agriculture Pvt.ltd. | <a href="tel:+918982471422"> Designed by Web solution & digital marketing</a>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
