import React from 'react'
import { Link } from "react-router-dom";


const GouvaPlant = () => {
  return (
    <>
      <section
        className="page-header clearfix"
        style={{
          backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)",
        }}
      >
        <div className="container">
          <div className="page-header__inner text-center clearfix">
            <ul className="thm-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Gallery</li>
            </ul>
            <h2>GouvaPlant</h2>
          </div>
        </div>
      </section>

      <section className="projects-one projects-one--two projects-one--two--projects">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="projects-one--two--projects__wrapper">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/GouvaPlant/1.jpeg"
                          alt
                        />
                        <div className="overlay-content">
                          <p>Agriculture</p>
                          <h3>
                            <Link to="">GouvaPlant</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/GouvaPlant/2.jpeg"
                          alt
                        />
                        <div className="overlay-content">
                          <p>Agriculture</p>
                          <h3>
                            <Link to="">GouvaPlant</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/GouvaPlant/3.jpeg"
                          alt
                        />
                        <div className="overlay-content">
                          <p>Agriculture</p>
                          <h3>
                            <Link to="">GouvaPlant</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/GouvaPlant/4.jpeg"
                          alt
                        />
                        <div className="overlay-content">
                          <p>Agriculture</p>
                          <h3>
                            <Link to="">GouvaPlant</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/GouvaPlant/8.jpeg"
                          alt
                        />
                        <div className="overlay-content">
                          <p>Agriculture</p>
                          <h3>
                            <Link to="">GouvaPlant</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/GouvaPlant/10.jpeg"
                          alt
                        />
                        <div className="overlay-content">
                          <p>Agriculture</p>
                          <h3>
                            <Link to="">GouvaPlant</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/GouvaPlant/7.jpeg"
                          alt
                        />
                        <div className="overlay-content">
                          <p>Agriculture</p>
                          <h3>
                            <Link to="">GouvaPlant</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/GouvaPlant/6.jpeg"
                          alt
                        />
                        <div className="overlay-content">
                          <p>Agriculture</p>
                          <h3>
                            <Link to="">GouvaPlant</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div
                      className="projects-one__single wow fadeInUp"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="projects-one__single-img">
                        <img
                          src="assets/images/project/GouvaPlant/11.jpeg"
                          alt
                        />
                        <div className="overlay-content">
                          <p>Agriculture</p>
                          <h3>
                            <Link to="">GouvaPlant</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GouvaPlant